import '../style.css'
const WhySection = () => {
  return (
    <section id='why-section'>
        <div className="container">
            <h3 className='why-title'>Alasan Mengapa Harus Ikutan J-1 USA</h3>
            <div className='container'>
                <div className='row'>
                    <div className="col-lg-12 col-md-6 col-sm-12">
                        <ul>
                            <li className='margin-bottom'><span className='text-bold'>Pengalaman Kerja Internasional:</span> Magang di Amerika Serikat memberikan kesempatan langka untuk mendapatkan pengalaman kerja internasional. Ini memungkinkan peserta magang untuk bekerja dalam lingkungan bisnis global.</li>
                            <li className='margin-bottom'><span className='text-bold'>Peningkatan Keterampilan dan Pengetahuan:</span> Peserta magang dapat mengembangkan keterampilan teknis dan soft skills yang berkualitas international.</li>
                            <li className='margin-bottom'><span className='text-bold'>Jaringan dan Kontak Profesional:</span> Mereka dapat berinteraksi dengan rekan-rekan kerja, mentor, dan profesional lainnya dalam industri mereka.</li>
                            <li className='margin-bottom'><span className='text-bold'>Peluang Karir:</span> Peluang karir yang mungkin terbuka setelah magang selesai. Peserta magang yang berkinerja baik sering memiliki peluang untuk mendapatkan pekerjaan tetap atau kontrak jangka panjang setelah magang mereka selesai.</li>
                        </ul>  
                    </div>
                </div>
            </div>
            <h3 className='why-title'>Requirements</h3>
            <div className='container'>
                <div className='row'>
                    <div className="col-lg-12 col-md-6 col-sm-12">
                        <ul>
                            <li>18 - 34 years old</li>
                            <li>Ability to speak English (Speaking English Actively)</li>
                            <li>For the previous J1 Visa holders, should be staying for 2 years in Indonesia before applying the second J1-Visa</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default WhySection