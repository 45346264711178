import AboutUs from './section/AboutUs';
import Testimoni from './section/Testimoni';
import Consultation from './section/Consultation';
import Footer from './section/Footer';
import Header from './section/Header';
import SuperStudent from './section/SuperStudent';
import Register from './section/Register';
import WhySection from './section/WhySection';
import Navbar from './section/navbar/Navbar';


function App() {
  return (
    <>
    <Navbar/>
    <Header/>
    <WhySection/>
    <AboutUs/>
    <Testimoni/>
    <SuperStudent/>
    <Consultation/>
    <Register/>
    <Footer/>
    </>
  );
}

export default App;
