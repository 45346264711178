import React from 'react'
import '../style.css'

import Fade from 'react-reveal/Fade';

const Testimoni = () => {
  return (
    <section id='testimoni'>
      <img src="/img/about-us.png" alt="" />
      <div className="testimoni">
        <Fade>
          <h5 className='title'>Testimoni J-1 USA</h5>
          <p>
            “Saya sangat senang sekali bisa meraih mimpi di Negeri Paman Sam, 
            disini saya sudah magang selama 2
            bulan dan banyak sekali ilmu hospitality yang saya dapat”.
          </p>
          <br/>
          <p className='text-bold'>I Wayan Jewan</p>
          <span>Star Restaurant, Las Vegas, US</span>
        </Fade>
      </div>
    </section>
  )
}

export default Testimoni