import React from 'react'
import '../style.css'
import testi1 from '../assets/testi1.MOV';
import testi2 from '../assets/testi2.MOV';

const SuperStudent = () => {
    return (
        <section id='super-student'>
            <div className='container'>
                <h5 className='why-title'>Register & Upload Document Pada Super Student Apps</h5>
                <div className='row justify-content-center m-5'>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <video src={testi1} controls className='video' />
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <video src={testi2} controls className='video' />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SuperStudent