import React from 'react'
import { Parallax, Background } from 'react-parallax';
import '../style.css'
const Consultation = () => {
  return (
    <Parallax strength={200} bgImage='/img/consultation-2.png'>
        <section id="consultation">
            <div className="container">
             <div className="row justify-content-between">
                 <div className="col-lg-8">
                     <div className="content1">
                         <h3>Free consultation with exceptional quality</h3>
                     </div>
                 </div>
                 <div className="col-lg-4 my-auto">
                     <div className="content2">
                        <a href="https://superstudent.niecindonesia.com/storage/announcement-resource/Proposal_J1.pdf" target="_blank">
                            <button className='btn'>Download Brochure</button>
                        </a>
                     </div>
                 </div>
             </div>
        </div>
        </section>
    </Parallax>
  )
}

export default Consultation