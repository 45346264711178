const FormValidation = (values) => {
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.nama_lengkap) {
        errors.nama_lengkap = "Nama Lengkap Wajib Diisi"
    }
    if (!values.no_wa) {
        errors.no_wa = "No Telepon Wajib Diisi"
    }
    if (!values.code_kelas) {
        errors.code_kelas = "Kelas Wajib Dipilih"
    }
    if (!values.code_jenjang) {
        errors.code_jenjang = "Jenjang Pendidikan Wajib Dipilih"
    }
    if (!values.id_propinsi) {
        errors.id_propinsi = "Provinsi Wajib Wajib Dipilih"
    }
    if (!values.id_kabkota) {
        errors.id_kabkota = "Kabupaten/Kota Wajib Dipilih"
    }
    if (!values.bornDate) {
        errors.bornDate = "Tanggal Lahir Wajib Dipilih"
    }
    if (!values.code_consultant) {
        errors.code_consultant = "Rekomender Wajib Dipilih"
    }
    // if (!values.sekolah) {
    //     errors.sekolah = "Sekolah/Kampus Wajib Diisi"
    // }
    // if (!values.atribut) {
    //     errors.atribut = "Keterangan Wajib Diisi"
    // }
    if (!values.email) {
      errors.email = "Email Wajib diisi";
    } else if (!regex.test(values.email)) {
      errors.email = "Email Invallid";
    }
    
    return errors;
}

export default FormValidation