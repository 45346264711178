import React from 'react'
import '../style.css'

import Fade from 'react-reveal/Fade';

const AboutUs = () => {
  return (
    <section id='about-us'>
      <img src="/img/about-us-2.png" alt="" />
      <div className="about-us">
        <h5 className='title'>What is the J-1 Exchange Visitor Program?</h5>
        <Fade>
          <p>
            J-1 visa is an Exchange Visitor non-immigrant visa issued by the United States to promote global
            understanding by means of educational and cultural exchange. This visa will allow non-U.S. citizens to
            legally stay in the United States and engage with Americans to understand and appreciate the American
            culture.
          </p>
        </Fade>
      </div>  
    </section>
  )
}

export default AboutUs